/**
 * websocket 工具类
 */
class websocketUtil {
    /**
     * 构造函数
     * @param {*} url 地址
     * @param {*} time 心跳检测时间
     */
    constructor(url, time) {
        this.socketTask = null; // websocket 实例
        this.token = null; // token
        this.message_callbacks = []; // 收到消息回调，可存多个实例
        this.is_open_socket = false; //避免重复连接，是否已开启连接
        this.url = url; // 地址
        this.data = null; // 发送消息内容
        //心跳检测
        this.timeout = time; //多少秒执行检测
        this.heartbeatInterval = null; //检测服务器端是否还活着
        this.reconnectTimeOut = null; //重连之后多久再次重连
    }

    /**
     * websocket初始化连接
     * @param {*} callback 
     * @returns 
     */
    socketInit(callback) {
        if (this.is_open_socket) {
            callback();
            return;
        }
        if (!this.token) return;
            
        // 创建 WebSocket 连接
        this.socketTask = new WebSocket(`${this.url}?Authorization=${this.token}`);

        // 监听连接建立事件
        this.socketTask.onopen = (event) => {
            console.log("【websocket】WebSocket连接建立正常！");
            clearTimeout(this.reconnectTimeOut)
            clearTimeout(this.heartbeatInterval)
            this.is_open_socket = true;
            this.start();
            this.listenMessage();
            if (callback) callback();
        };

        // 监听 WebSocket 错误事件
        this.socketTask.onerror = (error) => {
            console.error('【websocket】WebSocket连接出错！', error);
            // this.is_open_socket = false;
            // this.reconnect();
        };

        // 这里仅是事件监听【如果socket关闭了会执行】
        this.socketTask.onclose = (res) => {
            console.log("【websocket】WebSocket连接已关闭！", res);
            this.is_open_socket = false;
            this.reconnect();
        };

    }
    // 发送消息
    send(value) {
        if (!this.is_open_socket) return false;
        // 注：只有连接正常打开中 ，才能正常成功发送消息
        this.socketTask.send(value);
        return true;
    }
    // 开启心跳检测
    start() {
        this.heartbeatInterval = setInterval(() => {
            this.data = {
                type: "heartbeat"
            }
            this.send(JSON.stringify(this.data));
        }, this.timeout);
    }
    // 重新连接
    reconnect() {
        //停止发送心跳
        clearInterval(this.heartbeatInterval)
        //如果不是人为关闭的话，进行重连
        if (!this.is_open_socket) {
            this.reconnectTimeOut = setTimeout(() => {
                this.socketInit();
            }, 3000)
        }
    }
    /**
     * 监听消息
     */
    listenMessage() {
        if (!this.is_open_socket) return false;
        this.socketTask.onmessage = (res) => {
            // 解析消息内容
            let data = JSON.parse(res.data);
            if (data.type !== "heartbeat") {
                // 遍历回调函数，执行回调
                for (let i = 0; i < this.message_callbacks.length; i++) {
                    this.message_callbacks[i](data);
                }
            }
        }
    }
    /**
     * 外部获取消息
     */
    getMessage(fun) {
        this.message_callbacks.push(fun);
    }
}

// 导出websocket工具，定义心跳连接为20秒
module.exports = new websocketUtil(process.env.VUE_APP_WS_URL, 20000);