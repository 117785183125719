import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import main from '@/views/main.vue'
import login from '@/views/login'
import NotFound from '@/views/404/index'

Vue.use(Router)

let router = new Router({
    mode: "history",
    routes: [
        // 添加 404 路由
        {
            path: '*',
            component: NotFound
        },
        {
            path: '/login',
            name: 'login',
            component: login,
            meta: {
                title: "登录"
            }
        },
        {
            path: '/',
            name: 'adminIndex',
            component: main,
            meta: {
                title: "首页"
            },
            children: [
                {
                    path: "/",
                    component: () => import("@/views/modules/index"),
                    meta: {
                        title: "首页"
                    },
                },
                // {
                //     path: "/carousel",
                //     component: () => import("@/views/modules/carousel"),
                //     meta: {
                //         title: "轮播图管理"
                //     },
                // },
                {
                    path: "/user",
                    component: () => import("@/views/modules/user"),
                    meta: {
                        title: "用户管理"
                    },
                },
                {
                    path: "/config",
                    component: () => import("@/views/modules/config"),
                    meta: {
                        title: "配置管理"
                    },
                },
                {
                    path: "/subject",
                    component: () => import("@/views/modules/subject"),
                    meta: {
                        title: "科目管理"
                    },
                },
                {
                    path: "/feedback",
                    component: () => import("@/views/modules/feedback"),
                    meta: {
                        title: "反馈中心"
                    },
                },
                {
                    path: "/order",
                    component: () => import("@/views/modules/order"),
                    meta: {
                        title: "订单中心"
                    },
                },
                {
                    path: "/withdraw",
                    component: () => import("@/views/modules/withdrawLog"),
                    meta: {
                        title: "提现管理"
                    },
                },
                {
                    path: "/service",
                    component: () => import("@/views/modules/service"),
                    meta: {
                        title: "客服中心"
                    },
                }
                
            ]
        }
    ]
});

/**
 * 路由守卫
 */
router.beforeEach(async (to, from, next) => {
    // 如果不是登录页面查看是否有token
    let token = store.getters._getToken;
    if (!token && to.path !== "/login") {
        next("/login");
    } else next();
});

export default router;